import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 143.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,143.000000) scale(0.100000,-0.100000)">
         
<path d="M690 935 c0 -28 4 -35 19 -35 18 0 20 -10 23 -124 3 -135 10 -154 72
-200 40 -30 121 -28 165 4 54 38 71 88 71 213 0 88 3 107 15 107 10 0 15 11
15 35 l0 35 -80 0 -80 0 0 -35 c0 -28 4 -35 20 -35 19 0 20 -7 20 -113 0 -120
-6 -140 -49 -150 -66 -16 -91 30 -91 169 0 87 1 94 20 94 16 0 20 7 20 35 l0
35 -80 0 -80 0 0 -35z m108 -10 c2 -2 -2 -12 -9 -24 -12 -18 -15 -19 -30 -5
-24 21 -13 47 15 38 11 -4 22 -8 24 -9z m221 -4 c0 -3 -4 -14 -8 -25 -7 -17
-8 -17 -24 -2 -25 26 -22 44 8 38 14 -2 25 -7 24 -11z"/>
<path d="M1105 935 l0 -35 153 0 152 0 0 35 0 35 -152 0 -153 0 0 -35z"/>
<path d="M1440 935 l0 -35 50 0 50 0 0 35 0 35 -50 0 -50 0 0 -35z"/>
<path d="M1585 935 c0 -30 3 -35 23 -35 18 0 22 -5 22 -34 l0 -35 -52 6 c-44
4 -59 1 -88 -19 -96 -65 -88 -204 16 -253 23 -11 47 -15 68 -11 17 3 66 6 109
5 l77 0 0 35 c0 33 -2 36 -30 36 l-30 0 6 170 7 170 -64 0 -64 0 0 -35z m85
-152 l0 -46 -25 23 c-29 28 -31 37 -9 61 24 26 34 15 34 -38z m-65 -27 c19
-14 29 -64 19 -97 -7 -24 -50 -40 -78 -29 -50 18 -60 77 -21 115 27 28 52 31
80 11z m78 -126 c22 -26 14 -40 -23 -40 -36 0 -43 11 -24 38 19 27 25 27 47 2z"/>
<path d="M1822 829 c-43 -28 -62 -67 -62 -126 0 -89 57 -143 150 -143 22 0 56
7 76 15 l35 14 -16 31 c-13 25 -19 29 -35 20 -33 -17 -98 -12 -120 10 -38 38
-26 43 86 38 l105 -4 -7 41 c-8 52 -30 88 -64 109 -39 23 -108 20 -148 -5z
m120 -62 c15 -23 7 -27 -47 -27 -59 0 -68 10 -32 35 23 17 66 12 79 -8z m-120
-70 c-16 -18 -17 -18 -26 2 -6 14 -5 25 4 36 11 14 14 13 26 -2 10 -15 10 -21
-4 -36z"/>
<path d="M1223 825 c-18 -8 -33 -10 -33 -5 0 6 -27 10 -60 10 l-60 0 0 -35 c0
-28 4 -35 20 -35 18 0 20 -7 20 -65 0 -58 -2 -65 -20 -65 -16 0 -20 -7 -20
-35 l0 -35 85 0 85 0 0 35 c0 31 -3 35 -25 35 -25 0 -26 2 -23 57 3 52 6 59
30 71 21 10 33 10 48 2 18 -10 20 -21 20 -105 l0 -95 65 0 65 0 0 35 c0 28 -4
35 -20 35 -18 0 -20 7 -20 59 0 82 -16 120 -56 137 -41 17 -60 17 -101 -1z
m-43 -47 c0 -6 -7 -20 -16 -30 -16 -17 -17 -17 -30 8 -18 35 -19 34 16 34 17
0 30 -5 30 -12z m0 -180 c0 -13 -48 -9 -52 3 -2 7 3 26 11 43 l16 32 12 -36
c7 -19 13 -38 13 -42z m180 7 c0 -17 -2 -18 -15 -4 -9 9 -13 23 -9 33 5 14 9
15 15 4 5 -7 9 -22 9 -33z"/>
<path d="M2060 795 c0 -28 4 -35 20 -35 18 0 20 -7 20 -65 0 -58 -2 -65 -20
-65 -16 0 -20 -7 -20 -35 l0 -35 90 0 90 0 0 35 c0 32 -2 35 -30 35 -30 0 -30
1 -30 53 0 29 5 58 12 65 17 17 48 15 48 -3 0 -10 11 -15 35 -15 l35 0 0 50 0
50 -53 0 c-29 0 -58 -5 -65 -12 -9 -9 -12 -9 -12 0 0 8 -20 12 -60 12 l-60 0
0 -35z m118 -10 c6 -6 -23 -45 -33 -45 -6 0 -16 10 -24 21 -11 18 -11 23 1 30
11 7 45 3 56 -6z m-5 -175 c4 -17 0 -20 -29 -20 -37 0 -43 13 -17 42 18 20 37
11 46 -22z"/>
<path d="M1620 495 l0 -35 210 0 210 0 0 35 0 35 -210 0 -210 0 0 -35z"/>
<path d="M2060 495 l0 -35 90 0 90 0 0 35 0 35 -90 0 -90 0 0 -35z m145 6 c3
-5 -3 -11 -12 -13 -25 -5 -63 3 -63 13 0 12 67 12 75 0z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
